import React from "react";
import { Link, graphql } from "gatsby";
import ExhibitLayout from "../../components/layout/layout-exhibit";

import Helmet from "react-helmet";

import PageSection from '../../components/layout/page-section';
import MultipleVenueMap from '../../components/maps/multiple-venue-map';
import NarrativeSummaryCell from '../../components/exhibits/narrative-summary-cell';

import '../../scss/pages/exhibits/honkytonkin.scss';

const narrativesForDisplay = (data) => {
  const references = [
    'western-swing-part-i',
    'western-swing-part-ii',
    'new-audiences-demographics-in-austin-1930-1950',
    'new-dance-halls',
    'the-nash-hernandez-orchestra',
    'forgotten-swing-and-rhythm-and-blues-of-johnny-simmons',
  ];

  const narratives = data.allNarrativesJson.edges.filter(({ node }) => references.includes(node.reference));

  return narratives.sort((a, b) => {
    return references.indexOf(a.node.reference) - references.indexOf(b.node.reference);
  });
};

export default (props) => {
  return (
    <ExhibitLayout pageContext={props.pageContext}>
      <Helmet>
        <title>The Wild Side of Life: The Rise of the Honky Tonks, 1940-1950</title>
        <meta name="title" content="The Wild Side of Life: The Rise of the Honky Tonks, 1940-1950" />
        <meta name="description" content="The mid-century war years—rather than the student movement and hippy cowboys of the sixties and seventies—were the scene’s first major turning point in Austin. By 1950, new styles, new publics, and new venues had formed a diverse set of musical spheres." />
        
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://local-memory.org/the-rise-of-the-honky-tonks/" />
        
        <meta property="og:title" content="The Wild Side of Life: The Rise of the Honky Tonks, 1940-1950" />
        <meta property="og:description" content="The mid-century war years—rather than the student movement and hippy cowboys of the sixties and seventies—were the scene’s first major turning point in Austin. By 1950, new styles, new publics, and new venues had formed a diverse set of musical spheres." />
        <meta property="og:image" content="https://local-memory.org/images/content/w/WildSideofLife.jpg" />
        
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://local-memory.org/the-rise-of-the-honky-tonks" />
        <meta property="twitter:title" content="The Wild Side of Life: The Rise of the Honky Tonks, 1940-1950" />
        <meta property="twitter:description" content="The mid-century war years—rather than the student movement and hippy cowboys of the sixties and seventies—were the scene’s first major turning point in Austin. By 1950, new styles, new publics, and new venues had formed a diverse set of musical spheres." />
        <meta property="twitter:image" content="https://local-memory.org/images/content/w/WildSideofLife.jpg" />
      </Helmet>
      <div className="honkytonkin-exhibit-special-layout">
        <PageSection background="gray" wrapperType="wrapper" bottomPadding="md">
          <div className="intro-row">
            <div className="intro-text-group">
              <div className="featured-thumb">
                <img src="/images/content/w/WildSideofLife.jpg" alt="Photo of Harry Choates and his band" />
                <div className="caption-block">
                  <span className="citation">
                    <a href="https://texashistory.unt.edu/ark:/67531/metapth202217/m1/1/?q=%22harry%20choates%22">[Photograph of Harry Choates and Band]</a>, photograph, Date Unknown; accessed December 19, 2020, University of North Texas Libraries, <a href="https://texashistory.unt.edu">The Portal to Texas History</a>; crediting Museum of the Gulf Coast.
                  </span>
                </div>
              </div>
              <div className="text-block">
                <h2>
                  <Link to="/the-rise-of-the-honky-tonks/narratives/the-wild-side-of-life">
                    Introduction: The Wild Side of Life
                  </Link>
                </h2>
                <p className="intro">
                  Music in Austin changed considerably during the 1940s. As the city grew and the war installed bases in its hinterlands, new audiences danced to new styles in new places. This was a Central Texas sea change. University students and orchestras had dominated dances during the previous two decades and live popular music during the Jazz Age-Depression was largely a collegiate affair. By 1950, however, wholly different publics had emerged and placed Western Swing, Rhythm and Blues, and Honky Tonks at the center of the city's music scene.
                </p>
                <p className="intro">
                  <Link to="/the-rise-of-the-honky-tonks/narratives/the-wild-side-of-life" className="read-more">
                    Read more…
                  </Link>
                </p>
              </div>
            </div>
            <div className="sidebar-group">
              <Link to="/the-rise-of-the-honky-tonks/narratives/new-dance-halls" className="map-wrapper">
                <div className="map">
                  <img src="/images/content/t/Travis_County_1940.jpg" />
                  <div className="marker owl-club" style={{ "top": "33%", "left": "37%"}} />
                  <div className="marker plantation" style={{ "top": "35%", "left": "39.5%" }} />
                  <div className="marker skyline" style={{ "top": "37%", "left": "39%" }} />
                  <div className="marker the-barn" style={{ "top": "10%", "left": "15%" }} />
                  <div className="marker prices-hall" style={{ "top": "13%", "left": "17%" }} />
                  <div className="marker threadgills" style={{ "top": "46%", "left": "40.5%" }} />
                  <div className="marker top-hat" style={{ "top": "73.5%", "left": "54.5%" }} />
                  <div className="marker el-morocco" style={{ "top": "70%", "left": "54%" }} />
                  <div className="marker club-81" style={{ "top": "72%", "left": "54.25%" }} />
                  <div className="marker the-windmill" style={{ "top": "79.5%", "left": "59.5%" }} />
                  <div className="marker cinderella" style={{ "top": "79%", "left": "58.5%" }} />
                  <div className="marker hollywood" style={{ "top": "77%", "left": "56.5%" }} />
                  <div className="marker the-village" style={{ "top": "69%", "left": "53.5%" }} />
                  <div className="marker dessau" style={{ "top": "24%", "left": "45.5%" }} />
                  <div className="marker elm-grove" style={{ "top": "63.5%", "left": "40%" }} />
                  <div className="marker sunset-tavern" style={{ "top": "34%", "left": "11%" }} />
                  <div className="marker hilltop-inn" style={{ "top": "36%", "left": "21%" }} />
                  <div className="marker copenhagen-inn" style={{ "top": "35.5%", "left": "20%" }} />
                  <div className="marker riverside-inn" style={{ "top": "57.5%", "left": "66.5%" }} />
                  <div className="marker moosehead-tavern" style={{ "top": "81%", "left": "26%" }} />
                  <div className="marker blue-goose" style={{ "top": "97%", "left": "8%" }} />
                </div>
                <p className="label">
                  The New Dance Halls
                </p>
              </Link>
            </div>
          </div>
        </PageSection>
        <PageSection background="dark-gray" wrapperType="wrapper" topPadding="md" bottomPadding="md">
          <ul className="exhibit-narrative-grid">
            {narrativesForDisplay(props.data).map(({ node }, idx) => (
              <NarrativeSummaryCell key={ idx } narrativeNode={ node } />
            ))}
          </ul>
        </PageSection>
      </div>
    </ExhibitLayout>
  );
};

export const query = graphql`
{
  site {
    siteMetadata {
      title
    }
  }
  allNarrativesJson {
    edges {
      node {
        reference
        exhibit
        title
        featuredImage
        blurb
        summary
        venues
      }
    }
  }
  allVenuesJson {
    edges {
      node {
        reference
        canonical_name
        locations {
          coordinates {
            lat
            lon
          }
        }
      }
    }
  }
}
`;
